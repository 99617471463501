import { KorpayAccordion } from "@components/korpay-accordion/korpay-accordion";
import { KorpayStyledAccordion, KorpayStyledAccordionContainer, KorpayStyledHeader, KorpayStyledHeaderTitle, KorpayStyledHeaderSubtitle } from "@components/korpay-accordion/korpay-accordion-style";
import { KorpayStyledAccordionWrapper } from "@components/korpay-card/styles/korpay-view-card-styles";
import { KorpaySection } from "@components/korpay-section/korpay-section";
import { useState } from "react";

export const KorpaySaberMaisSection = () => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(3);

  const handleAccordionClick = (index: number) => {
    if (index === openAccordionIndex) {
      setOpenAccordionIndex(null);
    } else {
      setOpenAccordionIndex(index);
    }
  };

  const accordionData = [
    {
      title: "Por que Escolher a Korpay?",
      content:
        "Na Korpay, oferecemos soluções financeiras eficientes e confiáveis para negócios locais e internacionais, com ética e integridade como princípios fundamentais. Nossa cultura é pautada em qualidade e responsabilidade financeira, proporcionando uma experiência excepcional para nossos clientes. Escolha a Korpay para confiabilidade e integridade em suas transações financeiras."
    },
    {
      title: "Como abrir uma conta na Korpay",
      content:
        'Abrir sua conta na Korpay é fácil! Preencha o formulário "QUERO UMA CONTA KORPAY" e nossa equipe de especialistas entrará em contato em breve. Estamos aqui para tornar sua experiência a mais tranquila possível e garantir que você tenha acesso a todas as nossas soluções financeiras de forma rápida e segura.'
    },
    {
      title: "Como posso receber minhas vendas através da Korpay?",
      content:
        "É muito fácil! Com a Korpay, você pode receber suas vendas de diversas maneiras: através de PIX, TED, boletos ou até mesmo por meio de nossas maquininhas. Escolha a opção que melhor se adapta ao seu negócio e comece a receber seus pagamentos com agilidade e segurança!"
    },
    {
      title: "Sou pessoa física, posso abrir conta na Korpay?",
      content:
        'Não, a Korpay é especializada em atender apenas contas de pessoas jurídicas (PJ). Nossa plataforma foi projetada para facilitar as operações financeiras de empresas, oferecendo soluções personalizadas que atendem às necessidades do seu negócio.'
    },
    {
      title: "Nossas Taxas",
      id: 'nossas-taxas',
      content: `
        <div class="tax-content">
          <p class="tax-intro">Nossa Marca, Sua Segurança: com a Korpay, você pode ter confiança na segurança dos seus investimentos. Com uma abordagem única e moderna, estamos prontos para atender às suas necessidades financeiras com responsabilidade e eficiência.</p>
          <table class="tax-table">
              <tr class="highlight"><td>Manutenção de conta</td><td>R$ <span>99,00</span></td></tr>
              <tr><td>Recebimento de PIX</td><td>R$ <span>0,69</span></td></tr>
              <tr><td>Saída de PIX</td><td>R$ <span>3,49</span></td></tr>
              <tr><td>TED</td><td>R$ <span>11,00</span></td></tr>
              <tr><td>Boleto de liquidação</td><td>R$ <span>3,50</span></td></tr>
              <tr class="special-row">
                <td>
                  Operações de Câmbio
                  <div class="subtitle">Correspondente cambial do Banco Braza S/A - Autorizado pelo Bacen</div>
                </td>
                <td>Sob consulta</td>
              </tr>
              <tr><td>Operações com maquininhas Korpay</td><td>Sob consulta</td></tr>
          </table>
        </div>
      `,
    },
  ];

  return (
    <KorpaySection id="saber-mais" color="#FFF">
      <KorpayStyledAccordionWrapper>
        <KorpayStyledHeader>
          <KorpayStyledHeaderTitle>
            Quer saber mais?
          </KorpayStyledHeaderTitle>
          <KorpayStyledHeaderSubtitle>
            A gente explica para você:
          </KorpayStyledHeaderSubtitle>
        </KorpayStyledHeader>

        <KorpayStyledAccordionContainer>
          {accordionData.map((item, index) => (
            <KorpayStyledAccordion 
              id={item?.id} 
              key={index} 
              $isActive={index === openAccordionIndex}
            >
              <KorpayAccordion
                isOpen={index === openAccordionIndex}
                onClick={() => handleAccordionClick(index)}
                title={item.title}
                content={item.content}
                isLast={index === accordionData.length - 1}
                fontSizeContent="18px"
                colorFontContent="#3A3A3A"
              />
            </KorpayStyledAccordion>
          ))}
        </KorpayStyledAccordionContainer>
      </KorpayStyledAccordionWrapper>
    </KorpaySection>
  );
};
