// --== Realizando importação das imagens
import Image01 from '@images/cover-view-03.png'
import Image03 from '@images/cover-view-05.png'

import { KorpayViewCardDetalhe } from "@components/korpay-card/korpay-view-card-second"
import { KorpayHorizontalDivider } from "@components/korpay-divider/korpay-horizontal-divider"
import { KorpaySection } from "@components/korpay-section/korpay-section"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"

export const KorpayDetalhesSection = () => {

    return (
        <KorpaySection id="detalhes" color="#FFF">
            <KorpayWrapper gap="50px" direction="column" paddingTop="6%" width="100%" >

                <KorpayViewCardDetalhe background="#EBECF0" paddingSides="64px" padding="24px" titleSize="32px"
                    subTitleSize="24px" heightImage="380px" direction="row" width="505px" widthImage="508px" gapImage="0"
                    title="Simplicidade no gerenciamento e controle absoluto" subTitle="Transforme a gestão da sua conta meios de pagamento em uma 
                    experiência simples e eficiente, tudo ao alcance do seu computador." borderTopRightRadius="24px" borderTopLeftRadius="29px"
                    image={Image01} lineTitle="40px" line="32px" borderBottomLeftRadius="33px" />

                <KorpayViewCardDetalhe minHeight="380px" paddingSides="64px" padding="16px" paddingImage="0 16px" widthImage="475px"
                    borderTopRightRadius="24px" heightImage="365px" titleSize="32px" subTitleSize="24px" width="505px" gapImage="0"
                    direction="row-reverse" title="Aplicativos Korpay"
                    subTitle="Com o app Korpay, você gerencia suas finanças com praticidade e segurança, diretamente do seu celular. 
                    Acompanhe transações, faça pagamentos e tenha controle total de sua conta a qualquer hora, em qualquer lugar" justify="space-around" line="32px" lineTitle="40px" image={Image03} />

            </KorpayWrapper>

            <KorpayWrapper width="100%" paddingTop="5%" height="5px">
                <KorpayHorizontalDivider background="#EAEAEA" />
            </KorpayWrapper>

        </KorpaySection>
    )
}