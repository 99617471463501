// --== Realizando importação das imagens
import Image01 from '@images/cover-view-mobile-03.svg'
import Image03 from '@images/cover-view-mobile-05.png'

import { KorpayViewCardDetalhe } from "@components/korpay-card/korpay-view-card-second"
import { KorpaySection } from "@components/korpay-section/korpay-section"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"
import { theme } from "@theme/korpay-theme"
import { KorpayBaixeAppSectionMobile } from "./korpay-baixe-app-section-mobile"
import { KorpaySaberMaisSectionMobile } from "./korpay-saber-mais-section-mobile"

export const KorpayDetalhesSectionMobile = () => {
    return (
        <div id="detalhes">
            <KorpaySection views={2.4} color={theme.black00}>
                <KorpayWrapper gap="40px" direction="column" color="#fff"
                    borderRadius="20px">

                    <KorpayWrapper gap="38px" direction="column" paddingLeft="20px" paddingRight="20px" paddingTop="30px">

                        <KorpayViewCardDetalhe width="100%" line='16.94px' paddingSides="16px" padding="16px" titleSize="18px"
                            subTitleSize="14px" heightImage="100%" direction="column"
                            title="Simplicidade no gerenciamento e controle absoluto"
                            subTitle="Transforme a gestão da sua conta meios de pagamento em uma 
                            experiência simples e eficiente, tudo ao alcance do seu computador." image={Image01} />

                        <KorpayViewCardDetalhe width="100%" line='16.94px' paddingSides="16px" padding="16px"
                            titleSize="18px" subTitleSize="14px" borderTopRightRadius="24px"
                            heightImage="100%" direction="column" title="Aplicativos Korpay"
                            subTitle="Com o app Korpay, você gerencia suas finanças com praticidade e segurança, diretamente do seu celular. 
                            Acompanhe transações, faça pagamentos e tenha controle total de sua conta a qualquer hora, em qualquer lugar" image={Image03} />

                        {/* Accordion */}
                        <KorpaySaberMaisSectionMobile />
                    </KorpayWrapper>

                    <KorpayBaixeAppSectionMobile />

                </KorpayWrapper>

            </KorpaySection>
        </div>
    )
}