import { 
    KorpayStyledBaixarAppContent, 
    KorpayStyledBaixarAppImage,
    KorpayStyledBaixarAppImageWrapper, 
    KorpayStyledBaixarAppSubTitle, 
    KorpayStyledBaixarAppTitle, 
    KorpayStyledBaixarAppWrapper 
} from "@components/korpay-card/styles/korpay-view-card-styles"
import imageBack from '@images/background-baixar.svg'
import image from '@images/baixe-app.png'

export const KorpayBaixeAppSection = () => {
    return (
        <div id="baixeApp">
            <KorpayStyledBaixarAppWrapper background={imageBack}>
                <KorpayStyledBaixarAppContent>
                    <KorpayStyledBaixarAppTitle>
                        Baixe o App e venha conhecer o futuro
                    </KorpayStyledBaixarAppTitle>
                    <KorpayStyledBaixarAppSubTitle>
                        No App você tem total controle da sua conta e segurança máxima.
                    </KorpayStyledBaixarAppSubTitle>
                </KorpayStyledBaixarAppContent>

                <KorpayStyledBaixarAppImageWrapper>
                    <KorpayStyledBaixarAppImage 
                        src={image} 
                        alt="Korpay App Preview" 
                        loading="lazy"
                    />
                </KorpayStyledBaixarAppImageWrapper>
            </KorpayStyledBaixarAppWrapper>
        </div>
    )
}