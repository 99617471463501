import styled, { css } from "styled-components";
import { KorpayAccordionProps } from "./korpay-accordion";
import OpenIcon from "@icons/arrow-down.svg";
import { theme } from "@theme/korpay-theme";

export const KorpayStyledHeader = styled.div`
  margin-bottom: 64px;
  text-align: center;
  padding: 0 20px;
`

export const KorpayStyledHeaderTitle = styled.h2`
  font-size: 42px;
  font-weight: 700;
  color: ${theme.black00};
  margin-bottom: 24px;
  background: linear-gradient(135deg, #171717 0%, #404040 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`

export const KorpayStyledHeaderSubtitle = styled.p`
  font-size: 24px;
  color: #565555;
  opacity: 0.9;
  line-height: 1.5;
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export const KorpayStyledAccordionContainer = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
`

export const KorpayStyledAccordion = styled.div<{ $isActive?: boolean }>`
  width: 100%;
  border-radius: 16px;
  transition: all 0.3s ease;
  overflow: hidden;
  padding: 4px 24px;
  
  &:hover {
    transform: translateY(-2px);
  }

  .tax-content {
    padding: 32px 20px;
    
    .tax-intro {
      margin-bottom: 32px;
      color: #565555;
      line-height: 1.6;
    }

    .tax-table {
      width: 100%;
      border-collapse: collapse;
      
      tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        transition: background-color 0.2s ease;
        
        &:hover {
          background-color: rgba(0, 0, 0, 0.02);
        }
        
        &.highlight {
          background-color: rgba(0, 0, 0, 0.03);
        }
        
        &.special-row {
          background-color: #f8f9fa;
          
          .subtitle {
            font-size: 12px;
            color: #808080;
            margin-top: 8px;
          }
        }
      }
      
      td {
        padding: 20px 24px;
        
        &:last-child {
          text-align: right;
          font-weight: 600;
          
          span {
            color: #2E7D32;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 16px 20px;
    
    .tax-table {
      td {
        padding: 16px 12px;
        font-size: 14px;
      }
    }
  }
`

export const KorpayStyledAccordionWrapper = styled.div`
  .header-wrapper {
    margin-bottom: 48px;
    text-align: center;
    
    .main-title {
      background: linear-gradient(135deg, #171717 0%, #404040 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 16px;
    }
    
    .subtitle {
      opacity: 0.9;
    }
  }

  .accordion-container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding: 24px 16px;
    
    .header-wrapper {
      .main-title {
        font-size: 32px;
      }
      
      .subtitle {
        font-size: 18px;
      }
    }
  }
`

export const KorpayStyledAccordionBorder = styled.div<KorpayAccordionProps>`
  border-bottom: 2px solid #ebecf0;
  transition: 0.5s;

  &.last-accordion {
    border-bottom: none;
  }
`;

export const KorpayStyledAccordionButton = styled.button<KorpayAccordionProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: transparent;
  padding-bottom: 12px;
  border: none;
  transition: 0.5s;

  ${(props) =>
    props.isMobile === false &&
    css`
      cursor: pointer;
    `}
`;

export const KorpayStyledAccordionTitle = styled.div<KorpayAccordionProps>`
  font-size: ${(props) => props.fontSize ?? "24px"};
  font-weight: 700;
  text-align: left;
  color: ${(props) => (props.isOpen ? "#ADBECE" : "#171717")};
  transition: 0.5s;
`;

export const KorpayStyledAccordionContent = styled.div<KorpayAccordionProps>`
  text-align: justify;
  color: ${(props) => props.colorFontContent};
  font-size: ${(props) => props.fontSizeContent ?? "22px"};
  padding: 10px 0;
  padding-left: 8px;
  transition: 0.5s;
  font-weight: 500;
  line-height: 150%;
  padding-right: 12px;
`;

export const KorpayStyledAccordionIcon = styled.div<KorpayAccordionProps>`
  transform: ${({ isOpen }) => (isOpen ? "rotate(-180deg)" : "rotate(0)")};
  background-image: ${`url(${OpenIcon})`};

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #adbece;
  transition: 0.7s;

  height: ${(props) => props.heightIcon ?? "34px"};
  width: ${(props) => props.widthIcon ?? "41px"};
  padding: ${(props) => props.paddingIcon ?? "0"};
`;
