import { KorpayTextCard } from "@components/korpay-card/korpay-text-card"
import { KorpayViewCard } from "@components/korpay-card/korpay-view-card"
import { KorpayHorizontalDivider } from "@components/korpay-divider/korpay-horizontal-divider"
import { KorpaySection } from "@components/korpay-section/korpay-section"
import { KorpayTypography } from "@components/korpay-typography/korpay-typography"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"
import { theme } from "@theme/korpay-theme"

// --== Realizando importação de ícones
import KorNotificationIcon from "@icons/notification.svg"
import KorShieldIcon from "@icons/shield.svg"
import KorWirelessIcon from "@icons/wireless.svg"

// --== Realizando importação de imagens
import { useKorpayAgent } from "@hooks/use-korpay-agent"
import KorViewCard01 from "@images/card-view-mobile-01.png"
import KorViewCard02 from "@images/card-view-mobile-02.png"
import { KorpayStyledButton } from "@components/korpay-button/korpay-button-styles"

export const KorpaySegurancaSectionMobile = () => {
    const agent = useKorpayAgent();

    return (
        <div id="seguranca">
            <KorpaySection views={2.1} color={theme.black00} texture={theme.black00}>

                <KorpayWrapper color="#262626" justify="center" borderRadius="24px"
                    direction="column" paddingLeft="30px" paddingRight="30px" gap="8px"
                    paddingBottom="30px" paddingTop="30px">

                    <KorpayWrapper width="100%" direction="column" gap="24px" align="center" paddingBottom="10px">
                        <KorpayTypography color={theme.white00} size={agent.isMobile() ? '24px' : "45px"} weight={700}>Sobre nós</KorpayTypography>
                        <KorpayWrapper width="80%" height="5px">
                            <KorpayHorizontalDivider size="3px" background="#383838" />
                        </KorpayWrapper>
                    </KorpayWrapper>

                    <KorpayWrapper direction="column">
                        <KorpayTextCard icon={KorWirelessIcon} text="Surgimento" titleSize="20px" isMobile={agent.isMobile()} sizeIcon="27px"
                            content="A KORPAY surgiu em 2021 com a missão de revolucionar o mercado financeiro,
                             oferecendo soluções práticas e objetivas para o seu negócio no dia-a-dia."/>

                        <KorpayTextCard icon={KorNotificationIcon} text="Exclusividade" titleSize="20px" isMobile={agent.isMobile()}
                            titleContent='16px' sizeIcon="27px" content="Chega de burocracia dos bancos convencionais,
                             oferecemos tudo que você precisa para uma gestão financeira de sucesso."/>

                        <KorpayTextCard icon={KorShieldIcon} text="Transparência" titleSize="20px"
                            isMobile={agent.isMobile()} sizeIcon="27px" content="Tempo, comodidade, foco no cliente e profissionais 
                            capacitados para tornar sua experiência ainda melhor."/>
                    </KorpayWrapper>

                </KorpayWrapper>

                <KorpayWrapper width="100%" marginBottom="50px" marginTop="50px">
                    <KorpayHorizontalDivider size="3px" />
                </KorpayWrapper>

                <KorpayWrapper width="100%" marginBottom="40px" borderRadius="30px">
                    <KorpayViewCard isMobile radius="30px" fontSize="16px" padding="8px" titleContent="Abrir sua conta na Korpay é simples e rápido. Oferecemos um processo descomplicado e seguro, permitindo que você tenha acesso imediato a todas as nossas soluções financeiras, com total transparência e sem burocracia.."
                        image={KorViewCard02} height="220px" title="Abertura de Conta na Korpay" />
                </KorpayWrapper>

                <KorpayWrapper width="100%" marginBottom="40px">
                    <KorpayViewCard isMobile radius="30px" fontSize="16px" padding="8px" titleContent="Com a Korpay, seus recebimentos são simplificados e seguros. Escolha a melhor forma de pagamento e receba com facilidade e sem complicações."
                        image={KorViewCard01} height="220px" title="Pagamentos com Korpay" />
                </KorpayWrapper>

                <KorpayWrapper width="100%" marginBottom="40px">
                    <KorpayStyledButton width="200px" fontSize="22px" height="55px"
                        radius="50px" fontWeight={700} color={theme.black00}
                        border="1.849px solid #F5F5F5" fontColor="#fff">
                        Veja mais
                    </KorpayStyledButton>
                </KorpayWrapper>

            </KorpaySection>
        </div>
    )
}